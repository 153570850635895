import { FC, ReactNode } from "react";
import styled, { css } from "styled-components";

const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
  }
`;

const ImageContainer = styled.div<{ imageLeft?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 20px;

  img {
    width: 100%;
    max-width: 550px;
    border: 3px solid #000;
    border-left: 8px solid #000;
    border-bottom: 8px solid #000;
    border-radius: 30px;
  }

  @media (max-width: 1080px) {
    margin-top: 50px;
    margin-left: 0px;
  }

  ${({ imageLeft }) =>
    imageLeft &&
    css`
      margin-left: 0;
      margin-right: 20px;
      justify-content: flex-start;
    `}
`;

export const Section: FC<{
  image: string;
  imageAlt: string;
  children: ReactNode | ReactNode[];
  imageLeft?: boolean;
}> = ({ image, imageAlt, children, imageLeft }) => (
  <SectionContainer>
    {imageLeft ? (
      <>
        <ImageContainer imageLeft={imageLeft}>
          <img src={image} alt={imageAlt} />
        </ImageContainer>
        <div>{children}</div>
      </>
    ) : (
      <>
        <div>{children}</div>
        <ImageContainer>
          <img src={image} alt={imageAlt} />
        </ImageContainer>
      </>
    )}
  </SectionContainer>
);
