import { FC } from "react";
import {
  ChangeHandler,
  useController,
  UseControllerProps,
} from "react-hook-form";
import styled from "styled-components";
import { Box } from "../../Box";
import { InputLabel } from "./InputLabel.styled";
import { StyledInput } from "./StyledInput.styled";

interface InputProps {
  label: string;
  key?: string;
  type: string;
  onChange: ChangeHandler;
  name: string;
  control: UseControllerProps["control"];
  error?: any;
}

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Input: FC<InputProps> = (props) => {
  const { field } = useController({ name: props.name, control: props.control });

  return (
    <FlexContainer>
      <>
        <InputLabel>{props.label}:</InputLabel>
        <label>
          <StyledInput type={props.type} {...field} />
        </label>
        {props.error ? (
          <Box marginLeft="5px">
            <p style={{ fontSize: "16px", marginTop: "7px" }}>{props.error}</p>
          </Box>
        ) : (
          <Box marginTop="43px" />
        )}
      </>
    </FlexContainer>
  );
};
