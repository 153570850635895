import { Link } from "@reach/router";
import { FC } from "react";
import styled from "styled-components";
import { AnchorLink } from "../components/Link";
import { Container } from "../components/Container";
import { Layout } from "../components/Layout";

const MenuLink = styled(Link)`
  text-decoration: none;
  color: #ff0054;
  font-family: "LeagueGothic", "Helvetica", "sans-serif";
  font-size: 30px;

  box-shadow: 0 -2px #ff0054 inset;
  text-transform: uppercase;

  transition: box-shadow 0.5s ease;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 -15px #ffb01f inset;
  }
`;

export const ComingSoon: FC<{ path: string }> = () => (
  <Layout>
    <Container
      style={{
        maxWidth: "90%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <center>
        <h1 style={{ color: "#390099" }}>Easy does it tiger!</h1>
        <p>
          YOU’VE STUMBLED ON A PAGE WE’RE STILL BUILDING. <br />
          LOVE THE ENTHUSIASM, WE ONLY JUST LAUNCHED OUR SITE A COUPLE DAYS AGO.{" "}
        </p>
        <br />
        <p>Looking for something specific?</p>
        <br />
        <AnchorLink
          width="170px"
          mobileWidth="150px"
          href="mailto:hello@acheecreative.com"
        >
          Flick us a message
        </AnchorLink>
        <br />
        <br />
        <br />

        <MenuLink to="/">Back to home</MenuLink>
      </center>
    </Container>
  </Layout>
);
