import { FC } from "react";
import styled from "styled-components";
import { AnchorLink } from "../components/Link";
import { Cards } from "../components/Cards";
import { Container } from "../components/Container";
import { Layout } from "../components/Layout";
import { Section } from "../components/Section";
import Jam from "../images/home/jam.png";

const homePageCards = [
  {
    path: "/services#design",
    title: "We design",
    subtitle: "Brand | UI/UX | Website",
  },
  {
    path: "/services#build",
    title: "We build",
    subtitle: "SQUARESPACE | WORDPRESS | WEBFLOW | CUSTOM CODING",
  },
  {
    path: "/services#support",
    title: "We support",
    subtitle: "BUG FIXING | CONTENT UPDATES",
  },
];

const UnderlineWhite = styled.span`
  @keyframes slidein {
    from {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) inset;
    }
    to {
      box-shadow: 0px -15px 0px 0px #f2ebff inset;
    }
  }

  animation: 0.75s ease-out slidein forwards;
  animation-delay: 0.25s;
`;

const UnderlineYellow = styled.span`
  @keyframes slideinyellow {
    from {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) inset;
    }
    to {
      box-shadow: 0px -15px 0px 0px #ffb01f inset;
    }
  }

  animation: 0.75s ease-out slideinyellow forwards;
  animation-delay: 0.25s;
`;

export const Home: FC<{ path: string }> = () => (
  <Layout>
    <Container>
      <h1>
        {/* TODO: add underline animation left to right ease in blue */}
        We craft <UnderlineYellow>digital experiences</UnderlineYellow> that
        speak to <UnderlineWhite>your customers</UnderlineWhite>
      </h1>
      <p style={{ textTransform: "unset" }}>
        As a website design and development studio we will create a website that
        reflects your brand. <br />
        Interested in learning more about how we can help you?
      </p>
      <br />
      <AnchorLink href="mailto:hello@acheecreative.com">Chat to us</AnchorLink>
      <br />

      {/* Add animation of happy customer in front of computer */}
    </Container>
    <Container style={{ backgroundColor: "#ff0054" }}>
      <h2>What we do</h2>
      <Cards cards={homePageCards} />
    </Container>

    <Container
      style={{
        backgroundColor: "#390099",
        paddingBottom: "150px",
      }}
    >
      <Section image={Jam} imageAlt="graphic vector of jam">
        <h2 style={{ color: "#ff0054" }}>Helping businesses grow is our jam</h2>
        <p
          style={{
            color: "#f2ebff",
            lineHeight: "35px",
            textTransform: "unset",
            letterSpacing: "1px",
          }}
        >
          We know that great websites can help you reach more customers, build
          trust, provide better customer service, and increase sales. We want to
          help you achieve that! <br />
          <br />
          We'll work with you to understand your needs and goals, and be there
          every step of the way to make sure your website is a success. <br />
          <br />
          Are you ready to take your business to the next level?
        </p>
        <br />
        <br />
        <AnchorLink href="mailto:hello@acheecreative.com" variant="secondary">
          Let's jam together!
        </AnchorLink>
      </Section>
    </Container>
  </Layout>
);
