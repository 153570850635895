import styled, { css } from "styled-components";

export const MenuItem = styled.li<{ active: boolean }>`
  font-family: "TitanOne", "sans-serif";
  font-size: 50px;
  opacity: 0;
  margin-left: 100px;

  @media (max-width: 768px) {
    font-size: 30px;
  }

  ${({ active }) =>
    active &&
    css`
      margin-left: 0;
      opacity: 1;
      transition: margin-left 1s ease, opacity 1s ease;

      transition-delay: 0.35s;
      &:nth-of-type(2) {
        transition-delay: 0.55s;
      }
      &:nth-of-type(3) {
        transition-delay: 0.75s;
      }
      &:nth-of-type(4) {
        transition-delay: 0.95s;
      }
      &:nth-of-type(5) {
        transition-delay: 1.15s;
      }
      &:nth-of-type(6) {
        transition-delay: 1.35s;
      }
      &:nth-of-type(7) {
        transition-delay: 1.55s;
      }
    `}
`;
