import styled from "styled-components";

export const StyledInput = styled.input`
  input& {
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-bottom: 7px solid #000;
    border-left: 7px solid #000;
    border-top: 3px solid #000;
    border-right: 3px solid #000;
    width: 350px;

    height: 30px;
    font-family: "LeagueGothic", "sans-serif";
    font-size: 20px;
    padding: 10px 10px;
    background-color: #ffadc9;
    margin-top: 10px;

    @media (max-width: 768px) {
      width: 200px;
    }
  }
`;
