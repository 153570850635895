import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./fonts/Titan_One/TitanOne-Regular.ttf";
import "./fonts/League_Gothic/static/LeagueGothic-Regular.ttf";
import { render } from "react-dom";

<link rel="preload" as="font"></link>;

render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
