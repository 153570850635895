import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { Box } from "../../Box";
import { Button } from "../../Button";
import { Link } from "../../Link";
import { Input } from "../Input";

export const ContactForm = () => {
  emailjs.init("_L8Rr1kLBzQmnn59i");
  const { handleSubmit, register, formState, control, getValues } = useForm();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);

    const values = getValues();

    emailjs.send("service_5m2bxkm", "template_lxalbwl", values).then(
      (result) => {
        console.log(result.text);

        setSuccess(true);
        setLoading(false);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  useEffect(() => {
    if (success) window.scrollTo(0, 0);
  });

  return (
    <>
      {!success ? (
        <>
          <h1 style={{ color: "#000" }}>Let's chat!</h1>
          <p style={{ textTransform: "unset" }}>
            Whether you need help with a technical issue, have a question about
            our services, or just want to say hello, we can’t wait to hear from
            you!
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <>
              <br />

              <Input
                label="Name"
                type="name"
                control={control}
                {...register("name", {
                  required: "Please fill out this field",
                })}
                error={formState.errors.name && formState.errors.name.message}
              />
              <br />
              <Input
                label="Email"
                type="email"
                control={control}
                {...register("email", {
                  required: "Please fill out this field",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Hmm, that doesn't look like an email",
                  },
                })}
                error={formState.errors.email && formState.errors.email.message}
              />
              <br />
              <Input
                label="Message"
                type="message"
                control={control}
                {...register("message", {
                  required: "Please fill out this field",
                })}
                error={
                  formState.errors.message && formState.errors.message.message
                }
              />
              <br />
              <Box marginBottom="50px">
                <Button
                  loading
                  type="submit"
                  width={loading ? "170px" : "150px"}
                >
                  {loading ? "Loading..." : "Submit"}
                </Button>
              </Box>
            </>
          </form>
        </>
      ) : (
        <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <center>
            <h1>Thank you for reaching out!</h1>
            <p>We will reach out to you via email in the next couple days.</p>
            <br />
            <Link to="/">Back to Home</Link>
          </center>
        </div>
      )}
    </>
  );
};
