import { Link } from "@reach/router";
import styled from "styled-components";

export const CardContainer = styled(Link)`
  max-width: 250px;
  height: 250px;

  border-top: 4px solid #000;
  border-right: 4px solid #000;
  border-left: 12px solid #000;
  border-bottom: 12px solid #000;
  background-color: #ffb01f;
  color: #000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  transition: background-color 0.5s ease, color 0.5s ease;
  text-decoration: none;

  #card-p {
    margin: 0;
    font-size: 20px;
  }
  #card-title {
    margin-bottom: 10px;
    margin-top: 20px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #390099;
    color: #f2ebff;

    #card-title {
      transition: color 0.5s ease;
      color: #f2ebff;
    }

    #card-p {
      transition: color 0.5s ease;

      color: #f2ebff;
    }

    #card-circle {
      transition: background-color 0.5s ease;

      background-color: #ffadc9;
    }
  }
`;
