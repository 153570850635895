import { FC } from "react";
import { Grid } from "../../Grid";
import { IconCard, IconCardProps } from "./IconCard";

interface IconCardsProps {
  cards: IconCardProps[];
  columns?: number;
  iconColor?: string;
  color?: string;
  marginBottom?: string;
}

export const IconCards: FC<IconCardsProps> = ({
  cards,
  columns,
  iconColor,
  color,
  marginBottom,
}) => (
  <Grid columns={columns} marginBottom={marginBottom}>
    {cards.map((card) => (
      <IconCard
        title={card.title}
        icon={card.icon}
        iconColor={iconColor}
        color={color}
      >
        {card.children}
      </IconCard>
    ))}
  </Grid>
);
