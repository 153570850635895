import { Link as LinkComponent } from "@reach/router";
import styled, { css } from "styled-components";

export const Link = styled(LinkComponent)<{
  width?: string;
  mobileWidth?: string;
  variant?: "primary" | "secondary" | "tertiary";
}>`
  margin-top: 30px;
  background-color: #ff0054;
  color: #f2ebff;
  font-family: "LeagueGothic", "sans-serif";
  font-size: 30px;
  text-transform: uppercase;
  padding: 10px 40px;
  text-decoration: none;
  transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out;
  width: 100px;
  border-top: 4px solid #000;
  border-right: 4px solid #000;
  border-left: 7px solid #000;
  border-bottom: 7px solid #000;

  &:hover,
  &:active,
  &:focus {
    background-color: #ffadc9;
    color: #ff0054;
  }

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  @media (max-width: 768px) {
    font-size: 25px;
    margin-left: 20px;

    ${({ mobileWidth }) =>
      mobileWidth &&
      css`
        width: ${mobileWidth};
      `}
  }

  ${({ variant }) =>
    variant === "secondary" &&
    css`
      background-color: #f2ebff;
      color: #ff0054;

      &:hover,
      &:active,
      &:focus {
        background-color: #ff0054;
        color: #f2ebff;
      }
    `}

  ${({ variant }) =>
    variant === "tertiary" &&
    css`
      border: none;
      padding: 0;
      padding-left: 5px;
      padding-right: 5px;
      width: unset;
      background-color: transparent;
      text-decoration: none;
      color: #ff0054;
      font-family: "LeagueGothic", "Helvetica", "sans-serif";
      font-size: 25px;

      box-shadow: 0 -2px #ff0054 inset;
      text-transform: uppercase;

      transition: box-shadow 0.5s ease;

      &:hover,
      &:focus,
      &:active {
        background-color: transparent;

        box-shadow: 0 -15px #ffb01f inset;
      }
    `}
`;
