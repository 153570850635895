import styled, { css } from "styled-components";

export const Grid = styled.div<{ columns?: number; marginBottom?: string }>`
  display: grid;
  grid-template-columns: ${({ columns = 3 }) => `repeat(${columns}, 1fr)`};
  gap: 50px;
  margin-bottom: 150px;

  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
  }

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `}
`;
