import { Link } from "@reach/router";
import styled from "styled-components";

export const MenuLink = styled(Link)`
  color: #390099;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover,
  &:focus,
  &:active {
    color: #ff0054;
  }
`;
