import styled from "styled-components";

export const CardsContainer = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  gap: 20px;
  padding-top: 45px;
  padding-bottom: 45px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
