import styled, { css } from "styled-components";

export const Menu = styled.ul<{ active: boolean }>`
  padding: 0;
  list-style: none;
  visibility: hidden;
  transition: visibility 1s ease;

  ${({ active }) =>
    active &&
    css`
      visibility: visible;
    `}

  @media (max-width: 768px) {
    margin: 20px;
    margin-top: 100px;
  }
`;
