import { ArrowRight } from "phosphor-react";
import { FC } from "react";
import styled from "styled-components";
import { CardContainer } from "./CardContainer.styled";
import { CardProps } from "./types";

const Circle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 40px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Card: FC<CardProps> = ({ path, title, subtitle }) => (
  <CardContainer to={path}>
    <div>
      <h3 id="card-title">{title}</h3>
      <p id="card-p">{subtitle}</p>
    </div>
    <Circle id="card-circle">
      <ArrowRight size={32} color="#f2ebff" />
    </Circle>
  </CardContainer>
);
