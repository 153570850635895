import { FC } from "react";
import { Cards } from "../components/Cards";
import { Container } from "../components/Container";
import { Layout } from "../components/Layout";

const workOneCards = [
  {
    path: "/coming-soon",
    title: "Salsa Therapy",
    subtitle: "Squarespace | Design | Development",
  },
  {
    path: "/coming-soon",
    title: "Sharesight",
    subtitle: "Front End Development | Custom Coding | React | Typescript",
  },
  {
    path: "/coming-soon",
    title: "Everything To Sea",
    subtitle: "Wordpress | Development",
  },
];

const workTwoCards = [
  {
    path: "/coming-soon",
    title: "Chakra Ma",
    subtitle: "Squarespace | Design | Development",
  },
  {
    path: "/coming-soon",
    title: "Baxter's Original",
    subtitle: "Squarespace | Design | Development",
  },

  {
    path: "/coming-soon",
    title: "Achee Creative",
    subtitle:
      "Front End Development | Custom Coding | React | Typescript | Design",
  },
];

export const Work: FC<{ path: string }> = () => (
  <Layout>
    <Container style={{ backgroundColor: "#f2ebff" }}>
      <h1 style={{ color: "#ff0054" }}>Work</h1>
      <p>
        We've been creating websites for years, and we're proud to share some of
        our favourite projects with you.
      </p>
      <br />
      <Cards cards={workOneCards} />

      <Cards cards={workTwoCards} />
    </Container>
  </Layout>
);
