import styled, { css } from "styled-components";

export const OverlayContainer = styled.div<{ active: boolean }>`
  background-color: #ffadc9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s;
  z-index: 50;
  transition: opacity 0.35s ease-in-out;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
      height: 100%;
    `}

  @media (max-width: 768px) {
    align-items: unset;
    justify-content: space-around;
    text-align: left;
  }
`;
