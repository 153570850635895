import styled, { css } from "styled-components";

export const Circle = styled.div<{ iconColor?: string }>`
  width: 150px;
  height: 150px;
  margin: 20px;
  border-radius: 100px;
  border: 3px solid #000;
  border-left: 10px solid #000;
  border-bottom: 10px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffb01f;

  ${({ iconColor }) =>
    iconColor &&
    css`
      background-color: ${iconColor};
    `}
`;
