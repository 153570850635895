import { FC } from "react";
import styled, { css } from "styled-components";

const burgerBase = () => css`
  width: 35px;
  height: 3px;
  border-radius: 2px;
  background-color: #000;
  z-index: 60;
`;

const TopBun = styled.div<{ active?: boolean }>`
  ${burgerBase}

  ${({ active }) =>
    active &&
    css`
      transform: translateY(10px) translateX(0) rotate(45deg);
      background: #ff0054;
    `}
`;
const Patty = styled.div<{ active?: boolean }>`
  ${burgerBase}
  margin-top: 7px;

  ${({ active }) =>
    active &&
    css`
      opacity: 0;
      background: #ff0054;
    `}
`;
const BottomBun = styled.div<{ active?: boolean }>`
  ${burgerBase}
  margin-top: 7px;

  ${({ active }) =>
    active &&
    css`
      transform: translateY(-10px) translateX(0) rotate(-45deg);
      background: #ff0054;
    `}
`;

const Burger = styled.div`
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 60;

  @media (max-width: 768px) {
    top: 20px;
    right: 20px;
  }
`;

export const BurgerIcon: FC<{ active?: boolean }> = ({ active }) => (
  <Burger>
    <TopBun active={active} />
    <Patty active={active} /> <BottomBun active={active} />
  </Burger>
);
