import { FC } from "react";
import { Container } from "../components/Container";
import { Layout } from "../components/Layout";
import { Grid } from "../components/Grid";
import styled from "styled-components";
import { Box } from "../components/Box";

import customerService from "../images/blog/customer-service.jpg";
import trustMeWebsite from "../images/blog/trust-me-website.jpg";
import websiteBuilder from "../images/blog/website-builder.jpg";

const BlogCard = styled.div`
  border: 2px solid #000;
  border-left: 7px solid #000;
  border-bottom: 7px solid #000;
  border-radius: 30px;

  width: 350px;
  overflow: hidden;
  background-color: #ffadc9;

  @media (max-width: 1200px) {
    width: 300px;
  }
  @media (max-width: 1080px) {
    width: 350px;
  }
  @media (max-width: 500px) {
    width: 250px;
  }

  transition: border 0.5s ease;

  &:hover,
  &:focus,
  &:active {
    border-left: 10px solid #000;
    border-bottom: 15px solid #000;
  }
`;

const BlogContent = styled.div`
  padding: 20px;
`;

const BlogImage = styled.img`
  border: 2px solid #000;
  border-left: 5px solid #000;
  border-bottom: 5px solid #000;
  border-radius: 25px;
  width: 300px;
  height: 200px;

  margin: 20px;

  @media (max-width: 1200px) {
    width: 250px;
    height: 180px;
  }
  @media (max-width: 1080px) {
    width: 300px;
    height: 200px;
  }
  @media (max-width: 500px) {
    width: 200px;
    height: 130px;
  }
`;

export const Blog: FC<{ path: string }> = () => (
  <Layout>
    <Container backgroundColor="#f2ebff">
      <Box marginBottom="100px">
        <h1 style={{ color: "#390099" }}>Blog</h1>
      </Box>
      <Grid>
        <BlogCard>
          <center>
            <BlogImage
              src={customerService}
              alt="lady taking an order from a customer"
            />
          </center>
          <BlogContent>
            <h4
              style={{
                fontFamily: "TitanOne",
                fontSize: "25px",
                margin: "0px",
                marginBottom: "10px",
              }}
            >
              Your Website: The Key to Better Customer Service
            </h4>
            <p
              style={{
                textTransform: "unset",
                fontSize: "20px",
              }}
            >
              14/07/2023 | Maisie Embleton
            </p>
            <p
              style={{
                textTransform: "unset",
                fontSize: "23px",
              }}
            >
              Want to provide better customer service? Start with your website.
              Learn how to use your website to answer customer questions,
              resolve issues, and make customers feel appreciated.
            </p>
          </BlogContent>
        </BlogCard>
        <BlogCard>
          <center>
            <BlogImage
              src={trustMeWebsite}
              alt="desktop computer with purple lights"
            />
          </center>
          <BlogContent>
            <h4
              style={{
                fontFamily: "TitanOne",
                fontSize: "25px",
                margin: "0px",
                marginBottom: "10px",
              }}
            >
              Trust me, you need a website
            </h4>
            <p
              style={{
                textTransform: "unset",
                fontSize: "20px",
              }}
            >
              14/07/2023 | Maisie Embleton
            </p>
            <p style={{ textTransform: "unset", fontSize: "23px" }}>
              Your website is more than just a place to sell products or
              services. It's also a powerful tool for building trust with your
              customers. Learn how to use your website to build trust and grow
              your business.
            </p>
          </BlogContent>
        </BlogCard>
        <BlogCard>
          <center>
            <BlogImage src={websiteBuilder} alt="Computer devices" />
          </center>

          <BlogContent>
            <h4
              style={{
                fontFamily: "TitanOne",
                fontSize: "25px",
                margin: "0px",
                marginBottom: "10px",
              }}
            >
              Squarespace, Webflow, Wix, Wordpress - Which One Is Right for You?
            </h4>
            <p
              style={{
                textTransform: "unset",
                fontSize: "20px",
              }}
            >
              14/07/2023 | Maisie Embleton
            </p>
            <p style={{ textTransform: "unset", fontSize: "23px" }}>
              Looking to create a website? Not sure which website builder is
              right for you? Read this blog post to learn about the different
              factors you need to consider when choosing a website builder, and
              get a comparison of the most popular website builders.
            </p>
          </BlogContent>
        </BlogCard>
      </Grid>
    </Container>
  </Layout>
);
