import styled, { keyframes } from "styled-components";

const GradientAnimation = keyframes`
    0%{background-position:0% 25%}
    50%{background-position:100% 76%}
    100%{background-position:0% 25%}
`;

export const AnimatedGradient = styled.div`
  background: linear-gradient(229deg, #ffb01f, #ffadc9, #f2ebff);
  background-size: 600% 600%;

  -webkit-animation: ${GradientAnimation} 13s ease infinite;
  -moz-animation: ${GradientAnimation} 13s ease infinite;
  animation: ${GradientAnimation} 13s ease infinite;
  min-height: 100vh;
  width: 100%;
`;
