import { Link } from "@reach/router";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { HamburgerMenu } from "./HamburgerMenu/HamburgerMenu";

const NavigationContainer = styled.div`
  width: 100%;
`;

const NavigationInner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 100px;

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const LogoText = styled.span`
  font-family: "TitanOne", "sans-serif";
  font-size: 20px;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;

  border-radius: 50px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  border-bottom: 5px solid #000;
  border-left: 5px solid #000;
  background-color: #f2ebff;
  top: 30px;
  left: 100px;
  z-index: 60;
  position: fixed;
  color: #ff0054;

  @media (max-width: 768px) {
    left: 20px;
  }
`;

const HomeLink = styled(Link)`
  text-decoration: none;
`;

export const Navigation = () => {
  const [active, setActive] = useState(false);

  const handleOnClick = useCallback(() => {
    setActive((prev) => !prev);
  }, []);

  const logoColour = active ? "#ff0054" : "#390099";
  return (
    <NavigationContainer>
      <NavigationInner>
        <HomeLink to="/">
          <LogoContainer>
            {/* <Logo
            src={logo}
            alt="Achee creative text with a graphic illustration of cat behind a laptop"
          /> */}
            <LogoText style={{ color: logoColour }}>Achee Creative</LogoText>
          </LogoContainer>
        </HomeLink>
        <HamburgerMenu active={active} handleOnClick={handleOnClick} />
      </NavigationInner>
    </NavigationContainer>
  );
};
