import styled from "styled-components";

export const SubmitButton = styled.input`
  background-color: #f2ebff;
  color: #ff0054;

  border-bottom: 7px solid #000;
  border-left: 7px solid #000;
  border-top: 3px solid #000;
  border-right: 3px solid #000;
  font-family: "LeagueGothic", "sans-serif";
  font-size: 25px;
  text-transform: uppercase;
  width: 120px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 15px;

  transition: background-color 0.5s ease, color 0.5s ease;

  &:hover,
  &:focus,
  &:active {
    color: #f2ebff;
    background-color: #ff0054;
  }

  @media (max-width: 1080px) {
    margin-left: 40px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;
