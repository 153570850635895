import { FC, ReactNode } from "react";
import { Circle } from "./Circle.styled";

export interface IconCardProps {
  icon: ReactNode;
  title: string;
  children: ReactNode;
  iconColor?: string;
  color?: string;
}

export const IconCard: FC<IconCardProps> = ({
  icon,
  title,
  children,
  iconColor,
  color = "#000",
}) => (
  <div>
    <center>
      <Circle iconColor={iconColor}>{icon}</Circle>
    </center>

    <p style={{ color }}>
      <strong>{title}</strong>
    </p>
    <p style={{ textTransform: "unset", color }}>{children}</p>
  </div>
);
