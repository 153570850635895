import styled from "styled-components";
import { NewsletterInput } from "../Form/NewsletterInput";
import { Link } from "../Link/Link.styled";
import { menuItems } from "../Navigation/menuItems";

const FooterContainer = styled.div`
  max-width: 100vw;
  background-color: #ffadc9;
  padding: 100px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1080px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    padding: 20px;
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

const MenuList = styled.ul`
  list-style: none;

  @media (max-width: 1080px) {
    padding-bottom: 30px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    padding-bottom: 30px;
  }
`;
const MenuItem = styled.li`
  margin-top: 10px;
  display: inline;
  margin-right: 20px;

  @media (max-width: 768px) {
    display: block;
  }
`;

const CompanyName = styled.p`
  padding-left: 40px;
  margin-top: 0;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const Footer = () => (
  <FooterContainer>
    <div>
      <CompanyName>Achee Creative</CompanyName>
      <MenuList>
        {menuItems.map((menuItem) => (
          <MenuItem key={menuItem.path}>
            <Link key={menuItem.path} to={menuItem.path} variant="tertiary">
              {menuItem.label}
            </Link>
          </MenuItem>
        ))}
      </MenuList>
    </div>

    <div>
      <NewsletterInput />

      {/* Todo add logic here */}
    </div>
  </FooterContainer>
);
