import React from "react";
import { Home } from "./pages";
import "./App.css";
import { Router } from "@reach/router";
import { ComingSoon } from "./pages/coming-soon";
import { Contact } from "./pages/contact";
import { Services } from "./pages/services/services";
import { Work } from "./pages/work";
import { Pricing } from "./pages/pricing";
import { Blog } from "./pages/blog";
import { About } from "./pages/about";

const App = () => {
  return (
    <Router>
      <Home path="/" />
      <ComingSoon path="/coming-soon" />
      <Contact path="/contact" />
      <Work path="/work" />
      <Pricing path="/pricing" />
      <Services path="/services" />
      <Blog path="/blog" />
      <About path="/about" />
    </Router>
  );
};

export default App;
