import {
  ArrowsClockwise,
  Browsers,
  FlowerLotus,
  Gear,
  GlobeHemisphereEast,
  Lock,
  MagnifyingGlass,
  PaintBrush,
  PaintBucket,
  TrendUp,
  UploadSimple,
  Users,
  Wrench,
} from "phosphor-react";

export const ourRecipeCards = [
  {
    icon: <GlobeHemisphereEast size={70} />,
    title: "Discover",
    children:
      "We want to create designs that reflects your brand and your personality. We'll work together to understand your brand, your goals, and your target audience.",
  },
  {
    icon: <PaintBrush size={70} />,
    title: "Create",
    children:
      "We use the latest design trends and technologies to create websites that are beautiful, functional, and easy to use. We also make sure your website is mobile-friendly, so that your customers can access it from anywhere.",
  },
  {
    icon: <ArrowsClockwise size={70} />,

    title: "Iterate",
    children:
      "We want your website to be something you love. That's why we'll get your feedback throughout the design process. We'll create wireframes and mockups for you to review, and we'll make changes based on your feedback.",
  },
];

export const buildCards = [
  {
    icon: <FlowerLotus size={70} />,
    title: "Beautiful",
    children:
      "We'll use high-quality images, videos, and fonts to make your website look its best.",
  },
  {
    icon: <Gear size={70} />,
    title: "Functional",
    children: "Your website will be easy to use and navigate.",
  },
  {
    icon: <Wrench size={70} />,
    title: "Easy to Maintain",
    children:
      "We'll make sure your website is easy for you to update and manage.",
  },
  {
    icon: <Lock size={70} />,
    title: "Secure",
    children:
      "We'll take steps to protect your website from hackers and malware.",
  },
];

export const supportFirstCards = [
  {
    icon: <MagnifyingGlass size={70} />,
    title: "Troubleshooting",
    children: "We'll help you diagnose and fix any problems with your website.",
  },
  {
    icon: <Wrench size={70} />,
    title: "Maintenance",
    children: "We'll keep your website up-to-date and running smoothly.",
  },
  {
    icon: <UploadSimple size={70} />,
    title: "Updates",
    children:
      "We'll help you update your website with the latest features and security patches.",
  },
  {
    icon: <PaintBucket size={70} />,
    title: "Customisation",
    children: "We'll help you make changes to your website to fit your needs.",
  },
];

export const supportSecondCards = [
  {
    icon: <Browsers size={70} />,
    title: "Search Engine Optimisation (SEO)",
    children: "We'll help you improve your website's search engine ranking.",
  },
  {
    icon: <TrendUp size={70} />,
    title: "Conversion Rate Optimisation (CRO)",
    children:
      "We'll help you increase the number of visitors who convert into customers.",
  },
  {
    icon: <Users size={70} />,
    title: "User Experience Design",
    children:
      "We'll help you make your website more user-friendly and engaging.",
  },
];
