import { FC, ReactNode, useEffect } from "react";
import styled from "styled-components";
import { AnimatedGradient } from "../AnimatedGradient";
import { Footer } from "../Footer";
import { Navigation } from "../Navigation";
import { Seo } from "../Seo";

const LayoutContainer = styled.div``;

export const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LayoutContainer>
      <Seo />
      <AnimatedGradient>
        <Navigation />
        {children}
      </AnimatedGradient>
      <Footer />
    </LayoutContainer>
  );
};
