import { FC } from "react";
import { Card } from ".";
import { CardsContainer } from "./CardsContainer.styled";
import { CardsProps } from "./types";

export const Cards: FC<CardsProps> = ({ cards, columns = 3 }) => (
  <CardsContainer columns={columns}>
    {cards.map((card) => (
      <Card path={card.path} title={card.title} subtitle={card.subtitle} />
    ))}
  </CardsContainer>
);
