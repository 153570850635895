import styled from "styled-components";

interface BoxProps {
  margin?: string;
  marginBottom?: string;
  marginTop?: string;
  marginRight?: string;
  marginLeft?: string;
  padding?: string;
  paddingBottom?: string;
  paddingTop?: string;
  paddingRight?: string;
  paddingLeft?: string;
  tabletMargin?: string;
  tabletMarginBottom?: string;
  tabletMarginTop?: string;
  tabletMarginRight?: string;
  tabletMarginLeft?: string;
  tabletPadding?: string;
  tabletPaddingBottom?: string;
  tabletPaddingTop?: string;
  tabletPaddingRight?: string;
  tabletPaddingLeft?: string;
  mobileMargin?: string;
  mobileMarginBottom?: string;
  mobileMarginTop?: string;
  mobileMarginRight?: string;
  mobileMarginLeft?: string;
  mobilePadding?: string;
  mobilePaddingBottom?: string;
  mobilePaddingTop?: string;
  mobilePaddingRight?: string;
  mobilePaddingLeft?: string;
}

export const Box = styled.div<BoxProps>`
  margin: ${({ margin }) => margin || "0px"};
  margin-bottom: ${({ marginBottom }) => marginBottom || "0px"};
  margin-left: ${({ marginLeft }) => marginLeft || "0px"};
  margin-right: ${({ marginRight }) => marginRight || "0px"};
  margin-top: ${({ marginTop }) => marginTop || "0px"};
  padding: ${({ padding }) => padding || "0px"};
  padding-bottom: ${({ paddingBottom }) => paddingBottom || "0px"};
  padding-left: ${({ paddingLeft }) => paddingLeft || "0px"};
  padding-right: ${({ paddingRight }) => paddingRight || "0px"};
  padding-top: ${({ paddingTop }) => paddingTop || "0px"};

  /* @media (max-width: 1080px) {
    margin: ${({ tabletMargin }) => tabletMargin || "0px"};
    margin-bottom: ${({ tabletMarginBottom }) => tabletMarginBottom || "0px"};
    margin-left: ${({ tabletMarginLeft }) => tabletMarginLeft || "0px"};
    margin-right: ${({ tabletMarginRight }) => tabletMarginRight || "0px"};
    margin-top: ${({ tabletMarginTop }) => tabletMarginTop || "0px"};
    padding: ${({ tabletPadding }) => tabletPadding || "0px"};
    padding-bottom: ${({ tabletPaddingBottom }) =>
    tabletPaddingBottom || "0px"};
    padding-left: ${({ tabletPaddingLeft }) => tabletPaddingLeft || "0px"};
    padding-right: ${({ tabletPaddingRight }) => tabletPaddingRight || "0px"};
    padding-top: ${({ tabletPaddingTop }) => tabletPaddingTop || "0px"};
  }

  @media (max-width: 768px) {
    margin: ${({ mobileMargin }) => mobileMargin || "0px"};
    margin-bottom: ${({ mobileMarginBottom }) => mobileMarginBottom || "0px"};
    margin-left: ${({ mobileMarginLeft }) => mobileMarginLeft || "0px"};
    margin-right: ${({ mobileMarginRight }) => mobileMarginRight || "0px"};
    margin-top: ${({ mobileMarginTop }) => mobileMarginTop || "0px"};
    padding: ${({ mobilePadding }) => mobilePadding || "0px"};
    padding-bottom: ${({ mobilePaddingBottom }) =>
    mobilePaddingBottom || "0px"};
    padding-left: ${({ mobilePaddingLeft }) => mobilePaddingLeft || "0px"};
    padding-right: ${({ mobilePaddingRight }) => mobilePaddingRight || "0px"};
    padding-top: ${({ mobilePaddingTop }) => mobilePaddingTop || "0px"};
  } */
`;
