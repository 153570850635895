import { useCallback, useState } from "react";
import styled from "styled-components";
import { InputLabel } from "../Input/InputLabel.styled";
import { StyledInput } from "../Input/StyledInput.styled";
import { SubmitButton } from "./SubmitButton.styled";

const InputContainer = styled.div`
  @media (max-width: 1080px) {
    margin-left: 40px;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

export const NewsletterInput = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleOnClick = useCallback(() => {
    setSubmitted(true);
  }, []);

  return (
    <div id="mc_embed_shell">
      <div id="mc_embed_signup">
        <form
          action="https://acheecreative.us17.list-manage.com/subscribe/post?u=b3b67ce3834a4279aa879505b&amp;id=bba06b7c4b&amp;f_id=009905e0f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_self"
          noValidate
        >
          <div id="mc_embed_signup_scroll">
            {!submitted ? (
              <div className="mc-field-group">
                <InputLabel htmlFor="mce-EMAIL">
                  Stay up to date: <span className="asterisk">*</span>
                </InputLabel>
                <InputContainer>
                  <StyledInput
                    type="email"
                    name="EMAIL"
                    className="required email"
                    id="mce-EMAIL"
                    required
                  />

                  <span
                    id="mce-EMAIL-HELPERTEXT"
                    className="helper_text"
                  ></span>
                </InputContainer>
              </div>
            ) : (
              <p>
                We appreciate your interest! Expect some sweet treats in your
                inbox soon.
              </p>
            )}
            {/* <div id="mce-responses" className="clear foot">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: "none" }}
              ></div>
              <div
                className="response"
                id="mce-success-response"
                style={{ display: "none" }}
              ></div>
            </div> */}
            <div
              aria-hidden="true"
              style={{ position: "absolute", left: "-5000px" }}
            >
              {/*  real people should not fill this in and expect good things - do
            not remove this or risk form bot signups */}
              <input
                type="text"
                name="b_b3b67ce3834a4279aa879505b_bba06b7c4b"
                tabIndex={-1}
                value=""
              />
            </div>
            <div className="optionalParent">
              <div className="clear foot">
                <SubmitButton
                  type="submit"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button"
                  value="Subscribe"
                  onClick={handleOnClick}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
