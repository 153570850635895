import styled from "styled-components";
import { BurgerIcon } from "./BurgerIcon";
import { MenuOverlay } from "../MenuOverlay/MenuOverlay";
import { StyledButton } from "./StyledButton.styled";

import type { FC } from "react";

const BurgerWrapper = styled.div`
  background-color: #f2ebff;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  border-left: 5px solid #000;
  border-bottom: 5px solid #000;
  padding: 15px;
  border-radius: 50px;
  position: fixed;
  top: 30px;
  right: 100px;

  z-index: 60;

  transition: background-color 0.5s ease;

  @media (max-width: 768px) {
    right: 30px;
  }
`;

export const HamburgerMenu: FC<{
  active: boolean;
  handleOnClick: () => void;
}> = ({ active, handleOnClick }) => {
  return (
    <>
      <StyledButton onClick={handleOnClick}>
        <BurgerWrapper>
          <BurgerIcon active={active} />
        </BurgerWrapper>
      </StyledButton>
      <MenuOverlay active={active} handleOnClick={handleOnClick} />
    </>
  );
};
