import { FC } from "react";
import { AnchorLink } from "../components/Link";
import { Container } from "../components/Container";
import { Layout } from "../components/Layout";
import { Section } from "../components/Section";

import people from "../images/about/people.jpg";
import cookieCutter from "../images/about/cookie-cutter.jpg";
import { Box } from "../components/Box";

export const About: FC<{ path: string }> = () => (
  <Layout>
    <Container style={{ backgroundColor: "#ff0054" }}>
      <h1 style={{ color: "#f2ebff" }}>Hey, we're Achee Creative</h1>
      <br />
      <Box marginBottom="50px">
        <Section image={people} imageAlt="people fist bumping the air">
          <p style={{ textTransform: "unset" }}>
            We're a team of passionate designers and developers who love helping
            businesses create beautiful, user-friendly websites that help them
            achieve their goals. <br />
            <br />
            <br />
            We believe that every business deserves a website that is as unique
            and delicious as their products or services. That's why we take the
            time to get to know each of our clients and understand their
            specific needs. We then work with them to create a website that is
            both visually appealing and functionally effective.
          </p>

          <br />
          <br />
          <br />
        </Section>
      </Box>
      <Section image={cookieCutter} imageAlt="cookie cutter" imageLeft>
        <p style={{ textTransform: "unset" }}>
          We're not your typical web design studio. We don't believe in
          cookie-cutter templates or one-size-fits-all solutions. We take the
          time to get to know our clients and their businesses, so we can create
          a website that is truly unique and effective.
        </p>
        <br />
        <br />
        <AnchorLink
          variant="secondary"
          width="170px"
          mobileWidth="150px"
          href="mailto:hello@acheecreative.com"
        >
          Flick us a message
        </AnchorLink>
        <br />
      </Section>
    </Container>
  </Layout>
);
