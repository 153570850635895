import styled from "styled-components";

export const InputLabel = styled.label`
  text-transform: uppercase;
  font-family: "LeagueGothic", "sans-serif";
  font-size: 25px;
  color: #000;
  padding-left: 5px;

  @media (max-width: 1080px) {
    /* margin-left: 40px; */
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;
