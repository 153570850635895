import { FC } from "react";
import styled from "styled-components";
import { Container } from "../components/Container";
import { Grid } from "../components/Grid";
import { Layout } from "../components/Layout";
import { AnchorLink } from "../components/Link";

const QuickLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 400px;
`;

export const Pricing: FC<{ path: string }> = () => (
  <Layout>
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
      backgroundColor="#f2ebff"
    >
      <h1 style={{ color: "#390099" }}>What's your investment?</h1>
      <p>
        We offer a variety of packages to fit your needs and budget. So whether
        you're looking for a basic website or a full-blown online store, we have
        something for you.
      </p>
      <center>
        <QuickLinksContainer>
          <AnchorLink href="#allinone" variant="tertiary">
            All in one
            <br />
            packages
          </AnchorLink>
          <AnchorLink href="#justbuildit" variant="tertiary">
            Build
            <br />
            packages
          </AnchorLink>
          <AnchorLink href="#supportpackages" variant="tertiary">
            Support
            <br />
            packages
          </AnchorLink>
        </QuickLinksContainer>
      </center>
    </Container>
    <Container id="allinone">
      <h2 style={{ color: "#390099" }}>All in one</h2>
      <p>Including design, building and support</p>

      <Grid>
        <div>
          <h2 style={{ color: "#000" }}>Sweet start</h2>
          <p>The perfect foundation for your website. </p>

          <p style={{ textTransform: "unset" }}>
            This package is perfect for businesses that are just starting out or
            that need a simple website to showcase their products or services.
          </p>

          <br />
          <p>Inclusions:</p>
          <p style={{ textTransform: "unset" }}>
            Brand Kit Website design <br />
            Up to 6 pages <br />
            Website built using Squarespace, Wordpress, Wix or Webflow -
            guidance provided if no preference <br />
            10 hours of consultation following launch <br />
            Training on the website platform
          </p>

          <p>Investment: $2500USD</p>
        </div>
        <div
          style={{
            borderTop: "2px solid #000",
            borderRight: "2px solid #000",
            borderLeft: "7px solid #000",
            borderBottom: "7px solid #000",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <h2 style={{ color: "#000" }}>Build and Scale</h2>
          <p>For those looking and building and scaling.</p>

          <p style={{ textTransform: "unset" }}>
            This package includes everything in the Sweet Start Package, plus a
            few extras, like a blog and e-commerce functionality.
          </p>
          <br />
          <p>Inclusions:</p>
          <p style={{ textTransform: "unset" }}>
            Brand Kit Website design <br />
            Up to 10 pages <br />
            Website built using Squarespace, Wordpress, Wix or Webflow -
            guidance provided if no preference <br />
            Premium feature such as blog, or e-commerce capabilities <br />
            10 hours of consultation following launch <br />
            Training on the website platform
          </p>
          <p>Investment: $4300USD</p>
        </div>
        <div>
          <h2 style={{ color: "#000" }}>
            Top <br />
            Tier
          </h2>
          <p>The most comprehensive package for your business.</p>

          <p style={{ textTransform: "unset" }}>
            This is our most comprehensive package, and it's perfect for
            businesses that need a fully-featured website.
          </p>
          <br />
          <p>Inclusions:</p>
          <p style={{ textTransform: "unset" }}>
            Brand Kit Website design <br />
            No limit on pages
            <br />
            Website built using Squarespace, Wordpress, Wix or Webflow -
            guidance provided if no preference <br />
            Premium features such as blog, or e-commerce capabilities <br />
            10 hours of consultation following launch <br />
            Training on the website platform
          </p>
          <p>Investment: $7500USD</p>
        </div>
      </Grid>
      <center>
        <p>Interested in the above packages?</p>
        <AnchorLink href="mailto:hello@acheecreative.com">
          Let's talk!
        </AnchorLink>
      </center>
      <br />
      <br />
      <br />
      <p style={{ textTransform: "unset" }}>
        *For all purchases of the All In One packages, we offer 20% off any
        additional support packages
      </p>
    </Container>
    <Container
      id="justbuildit"
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
      backgroundColor="#ffb01f"
    >
      <h2 style={{ color: "#390099" }}>Just Build It</h2>
      <p>
        Already have your brand materials and designs for your website and need
        some expertise in building it?
      </p>

      <Grid>
        <div>
          <h2 style={{ color: "#000" }}>Sweet start</h2>
          <p>Up to 6 pages</p>
          <p>Investment: $500USD</p>
          <p>
            Prerequisites: <br />
            Brand kit including colours, typography, logo <br />
            Wireframes of the website <br />
            Website content including copy and images
          </p>
        </div>
        <div
          style={{
            borderTop: "2px solid #000",
            borderRight: "2px solid #000",
            borderLeft: "7px solid #000",
            borderBottom: "7px solid #000",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <h2 style={{ color: "#000" }}>Build and scale</h2>
          <p>Up to 10 pages</p>
          <p>Investment: $800USD</p>

          <p>
            Prerequisites: <br />
            Brand kit including colours, typography, logo <br />
            Wireframes of the website <br />
            Website content including copy and images
          </p>
        </div>
        <div>
          <h2 style={{ color: "#000" }}>
            Top <br />
            tier
          </h2>
          <p>The sky's the limit</p>
          <p>Investment: $1500USD</p>

          <p>
            Prerequisites: <br />
            Brand kit including colours, typography, logo <br />
            Wireframes of the website <br />
            Website content including copy and images
          </p>
        </div>
      </Grid>
      <center>
        <p>Interested in the above packages?</p>
        <AnchorLink href="mailto:hello@acheecreative.com">
          Let's talk!
        </AnchorLink>
      </center>
      <br />
      <br />
      <br />
      <p style={{ textTransform: "unset" }}>
        *For all purchases of the Build packages, we offer 20% off any
        additional support packages
      </p>
    </Container>
    <Container
      id="supportpackages"
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
      backgroundColor="#f2ebff"
    >
      <h2 style={{ color: "#390099" }}>Support</h2>
      <p>Already have your website but need some support or improvements?</p>

      <Grid>
        <div>
          <h2 style={{ color: "#000" }}>You call, we come</h2>
          <p>For technical support or website updates needed on ad hoc basis</p>
          <p>Investment: $50USD Per hour</p>
        </div>
        <div>
          <h2 style={{ color: "#000" }}>Here to help</h2>
          <p>
            For technical support or website updates needed on a regular basis
          </p>
          <p>10 hours consulation</p>

          <p>Investment: $400USD per month</p>
        </div>
        <div
          style={{
            borderTop: "2px solid #000",
            borderRight: "2px solid #000",
            borderLeft: "7px solid #000",
            borderBottom: "7px solid #000",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <h2 style={{ color: "#000" }}>Iterate & Improve</h2>
          <p>
            For technical support or website updates needed on a regular basis
          </p>
          <p>20 hours consulation</p>

          <p>Investment: $700USD per month</p>
        </div>
      </Grid>
      <center>
        <p>Interested in the above packages?</p>
        <AnchorLink href="mailto:hello@acheecreative.com">
          Let's talk!
        </AnchorLink>
      </center>
    </Container>
  </Layout>
);
