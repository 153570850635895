import { FC } from "react";
import { Container } from "../components/Container";
import { Layout } from "../components/Layout";
import { ContactForm } from "../components/Form/ContactForm";

export const Contact: FC<{ path: string }> = () => (
  <Layout>
    <Container
      style={{
        backgroundColor: "#ffb01f",
      }}
    >
      <ContactForm />
    </Container>
  </Layout>
);
