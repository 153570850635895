import styled, { css } from "styled-components";

export const Container = styled.div<{ backgroundColor?: string }>`
  padding: 100px;
  padding-bottom: 150px;

  @media (max-width: 768px) {
    padding: 20px;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background: ${backgroundColor};
    `}
`;
