import { AnchorLink } from "../../Link";
import { Menu } from "./Menu.styled";
import { OverlayContainer } from "./OverlayContainer.styled";

import type { FC } from "react";
import { MenuItem } from "./MenuItem.styled";
import { MenuLink } from "./MenuLink.styled";
import { menuItems } from "../menuItems";

export const MenuOverlay: FC<{
  active: boolean;
  handleOnClick: () => void;
}> = ({ active, handleOnClick }) => (
  <OverlayContainer active={active}>
    <Menu active={active}>
      {menuItems.map((menuItem) => (
        <MenuItem active={active}>
          <MenuLink to={menuItem.path} onClick={handleOnClick}>
            {menuItem.label}
          </MenuLink>
        </MenuItem>
      ))}
    </Menu>
    <AnchorLink
      href="mailto:hello@acheecreative.com"
      width="150px"
      mobileWidth="80px"
    >
      Let's chat!
    </AnchorLink>
  </OverlayContainer>
);
