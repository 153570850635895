import { FC } from "react";
import styled from "styled-components";
import { Container } from "../../components/Container";
import { Layout } from "../../components/Layout";
import { Link, AnchorLink } from "../../components/Link";

import SquarespaceLogo from "../../images/services/squarespace-logo.png";
import WebflowLogo from "../../images/services/webflow-logo.png";
import WordpressLogo from "../../images/services/wordpress-logo.png";
import WixLogo from "../../images/services/wix-logo.png";
import ReactLogo from "../../images/services/react-logo.png";
import { Grid } from "../../components/Grid";
import {
  buildCards,
  ourRecipeCards,
  supportFirstCards,
  supportSecondCards,
} from "./services-data";
import { IconCards } from "../../components/Cards/IconCards";

const QuickLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 400px;
`;

const Square = styled.div`
  width: 150px;
  height: 130px;
  border-radius: 40px;
  border: 3px solid #000;
  border-left: 10px solid #000;
  border-bottom: 10px solid #000;
  background-color: #f2ebff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Services: FC<{ path: string }> = () => (
  <Layout>
    <Container
      style={{
        backgroundColor: "#f2ebff",
      }}
    >
      <h1 style={{ color: "#390099" }}>What we do</h1>
      <p style={{ textTransform: "unset" }}>
        We help businesses spread the sweet taste of success with websites that
        are jam-packed with features, candy-coated with design, and sweet to the
        touch. We use the latest technologies and best practices to create
        websites that look delicious and get results.
      </p>
      <br />
      <center>
        <QuickLinksContainer>
          <AnchorLink href="#design" variant="tertiary">
            Design
          </AnchorLink>
          <AnchorLink href="#build" variant="tertiary">
            Build
          </AnchorLink>
          <AnchorLink href="#support" variant="tertiary">
            Support
          </AnchorLink>
        </QuickLinksContainer>
      </center>
    </Container>
    <Container id="design" style={{ backgroundColor: "#ff0054" }}>
      <h2 style={{ color: "#f2ebff" }}>Design</h2>
      <p>
        <i>The first bite is always the sweetest</i>
      </p>
      <br />
      <p style={{ textTransform: "unset" }}>
        That's why we put so much care into the design of your website. We want
        your website to be sweet to the eye and delicious to use.
      </p>
      <Grid>
        <div>
          <p>
            <strong>Brand design</strong>
          </p>
          <p style={{ textTransform: "unset" }}>
            What makes your business unique? What are your values? What do you
            want your customers to feel when they interact with your brand?
            <br />
            <br />
            We'll work with you to answer these questions and create a brand
            identity that is sweet, memorable, and on-brand. Having a solid
            brand lays the foundation for a cohesive online identity.
          </p>
        </div>
        <div>
          <p>
            <strong>User Experience Design</strong>
          </p>
          <p style={{ textTransform: "unset" }}>
            How do you want your customers to interact with your website? What
            do you want them to be able to do? <br />
            <br />
            We'll design your website with user-friendliness in mind. We'll make
            sure your website is easy to use and intuitive. We want your
            customers to be able to find what they're looking for quickly and
            easily.
          </p>
        </div>
        <div>
          <p>
            <strong>USER INTERFACE / WEBSITE DESIGN</strong>
          </p>
          <p style={{ textTransform: "unset" }}>
            What kind of look and feel do you want for your website? <br />
            <br />
            We'll create a website design that is beautiful, functional,
            accessible and on-brand. We'll make sure your website is
            mobile-friendly so that your customers can access it from anywhere.
          </p>
        </div>
      </Grid>
      <h2 style={{ color: "#f2ebff" }}>Our Recipe</h2>
      <IconCards cards={ourRecipeCards} />

      <center>
        <Link to="/coming-soon" variant="secondary">
          Design Packages
        </Link>
      </center>
    </Container>
    <Container id="build" style={{ backgroundColor: "#390099" }}>
      <h2 style={{ color: "#ff0054" }}>Build</h2>
      <p style={{ color: "#f2ebff" }}>
        <i>Just like a well-made dessert</i>
      </p>
      <br />
      <p style={{ textTransform: "unset", color: "#f2ebff" }}>
        A well-built website is a carefully crafted experience that will delight
        and engage your visitors.
      </p>
      <p style={{ textTransform: "unset", color: "#f2ebff" }}>
        We know that a great website is more than just how good it looks. It's
        also about functionality and performance. That's why we take the time to
        build your website with the latest technologies and best practices.
        <br />
        <br />
        We use responsive design so that your website looks great on all
        devices, from desktop to mobile. We also optimize your website for
        search engines so that more people can find you online.
      </p>
      <IconCards
        cards={buildCards}
        iconColor="#ffadc9"
        color="#f2ebff"
        columns={4}
      />
      <h2 style={{ color: "#ff0054" }}>Our Tools</h2>
      <Grid columns={5}>
        <div>
          <center>
            <Square>
              <img
                style={{ width: "150px" }}
                src={SquarespaceLogo}
                alt="squarespace logo"
              />
            </Square>
            <p style={{ color: "#f2ebff" }}>
              <strong>Squarespace</strong>
            </p>
          </center>
        </div>
        <div>
          <center>
            <Square>
              <img
                style={{ width: "80px" }}
                src={WebflowLogo}
                alt="webflow logo"
              />
            </Square>
            <p style={{ color: "#f2ebff" }}>
              <strong>Webflow</strong>
            </p>
          </center>
        </div>
        <div>
          <center>
            <Square>
              <img style={{ width: "80px" }} src={ReactLogo} alt="react logo" />
            </Square>
            <p style={{ color: "#f2ebff" }}>
              <strong>Custom Coding</strong>
            </p>
          </center>
        </div>
        <div>
          <center>
            <Square>
              <img
                style={{ width: "150px" }}
                src={WordpressLogo}
                alt="wordpress logo"
              />
            </Square>
            <p style={{ color: "#f2ebff" }}>
              <strong>Wordpress</strong>
            </p>
          </center>
        </div>
        <div>
          <center>
            <Square>
              <img style={{ width: "110px" }} src={WixLogo} alt="wix logo" />
            </Square>
            <p style={{ color: "#f2ebff" }}>
              <strong>Wix</strong>
            </p>
          </center>
        </div>
      </Grid>
      <center>
        <Link to="/coming-soon" variant="secondary">
          Build Packages
        </Link>
      </center>
    </Container>
    <Container id="support" style={{ backgroundColor: "#ffb01f" }}>
      <h2 style={{ color: "#390099" }}>Support</h2>
      <p>
        <i>Trifle your troubles away</i>
      </p>
      <br />
      <p style={{ textTransform: "unset" }}>
        We know that your website is important to you. That's why we offer sweet
        technical support to keep your website running smoothly.
        <br />
        <br />
        We can help you with anything from fixing a minor glitch to major
        website overhaul. We're here to help you with all your website needs, so
        you can focus on what you do best: running your business.
      </p>

      <IconCards
        cards={supportFirstCards}
        columns={4}
        iconColor="#f2ebff"
        marginBottom="30px"
      />
      <IconCards cards={supportSecondCards} iconColor="#f2ebff" />

      <center>
        <p>
          Whether you need help with a technical issue or you want to improve
          your website, we're here for you.
        </p>
        <br />
        <Link to="/coming-soon" variant="secondary">
          Support Packages
        </Link>
      </center>
    </Container>
  </Layout>
);
